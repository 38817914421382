import { handleActions } from 'redux-actions';

import { fetchAllAccountsResponse } from 'shared/store/modules/accounts/actions';
import {
  createInquiryRequest,
  createInquiryResponse,
  createProspectWithPremise,
  creditCheckRequest,
  creditCheckResponse,
  error,
  fetchClaimTicketRequest,
  fetchClaimTicketResponse,
  fetchContractDetailsResponse,
  fetchContractDetailsWithProspectIdResponse,
  fetchCreditCheckActionResponse,
  fetchInquiryInfoRequest,
  fetchInquiryInfoResponse,
  fetchRequest,
  fetchSavingsRequest,
  fetchSavingsResponse,
  fetchUtilityMeters,
  getLegalTemplate,
  getOffers,
  getRateTypes,
  getUtilAuthUrlResponse,
  invalidateContractRequest,
  loginRequest,
  loginResponse,
  patchInquiryRequest,
  patchInquiryResponse,
  patchProspectRequest,
  patchProspectResponse,
  resendInviteRequest,
  resendInviteResponse,
  resetZipCodeRequest,
  selectMetersForActivation,
  selectOffer,
  setPasswordRequest,
  setPasswordResponse,
  sign,
  skipUtilityLookup,
  useUtilityLookup,
  validateContractRequest,
  validateContractResponse,
  validateTokenRequest,
  validateTokenResponse,
  signupRequest,
  signupResponse,
  fetchProspectResponse
} from 'shared/store/modules/prospects/actions';

const savings = {
  powerSavings: 0,
  powerCost: 0,
  powerGenerated: 0,
  amountCo2: 0,
  amountTrees: 0,
  amountAcres: 0
};

const defaultState = {
  counties: [],
  utilities: [],
  rateTypes: [],
  claimTicket: null,
  data: null,
  savingsIsAnnual: false,
  error: null,
  isLoading: false,
  contractReady: false,
  contractURL: null,
  createInquiryResultStamp: 0,
  useUtilityLookup: true,
  canUseUtilityApiLookup: false,
  savings: {
    annual: savings,
    fiveYear: savings
  },
  legal: {},
  selectedOffer: null
};

const setOffer = (offers, offerId) => {
  return offers.map(offer => {
    if (offer.id === offerId) {
      offer.selected = true;
    }
    return offer;
  });
};

// Reducer
export const reducer = handleActions(
  {
    [patchProspectRequest]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: true
      })
    },

    [patchProspectResponse]: {
      next: (state, { payload }) => ({
        ...state,
        claimTicket: payload.claimTicket,
        isLoading: false
      })
    },

    [loginResponse]: {
      next: (state, { payload }) => ({
        ...state,
        access_token: payload.access_token,
        expires_in: payload.expires_in * 1000 + Date.now(),
        refresh_token: payload.refresh_token,
        customerId: payload.customerId,
        portfolioId: payload.portfolioId,
        role: payload.role,
        userId: payload.userId,
        userName: payload.userName
      }),

      throw: (state, action) => ({
        ...state,
        message: action.payload.message
      })
    },

    [loginRequest]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: true
      }),

      throw: (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.message
      })
    },

    [fetchRequest]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: true
      }),

      throw: (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.message
      })
    },

    [error]: {
      next: (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.message
      })
    },

    [resetZipCodeRequest]: {
      next: state => {
        return {
          ...state,
          error: null,
          zipCode: '',
          data: null,
          isLoading: false
        };
      }
    },

    [fetchSavingsRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          savingsIsAnnual: action.payload.isAnnual,
          zipCode: action.payload.zipCode,
          powerCost: action.payload.powerCost,
          isLoading: true
        };
      }
    },

    [fetchSavingsResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          savings: action.payload,
          isLoading: false
        };
      }
    },

    [fetchInquiryInfoRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isLoading: true,
          zipCode: action.payload
        };
      }
    },

    [fetchInquiryInfoResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          counties: action.payload.counties,
          utilities: action.payload.utilities,
          data: action.payload,
          isLoading: false
        };
      },
      throw: (state, action) => ({
        ...state,
        message: action.payload.message,
        isLoading: false
      })
    },

    [createInquiryRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          zipCode: action.payload.zipCode,
          utilityId: action.payload.utilityId,
          countyId: action.payload.countyId,
          referralCode: action.payload.referralCode,
          isSubmitting: true
        };
      }
    },

    [createInquiryResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          createInquiryResult: action.payload.result,
          createInquiryResultStamp: new Date().getTime(),
          isSubmitting: false
        };
      }
    },

    [patchInquiryRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isSubmitting: true
        };
      }
    },

    [patchInquiryResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          prospectId: action.payload.prospectId,
          nextStep: action.payload.nextStepToComplete,
          isSubmitting: false
        };
      }
    },

    [createProspectWithPremise]: {
      next: (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: null
        };
      }
    },

    [resendInviteRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isSubmitting: true
        };
      }
    },

    [resendInviteResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isSubmitting: false,
          TESTFLAG: true
        };
      },
      throw: (state, payload) => {
        return {
          ...state,
          error: payload.message,
          isSubmitting: false
        };
      }
    },

    [validateTokenRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isLoading: true
        };
      }
    },

    [signupRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isLoading: true
        };
      }
    },

    [getUtilAuthUrlResponse]: {
      next: (state, { payload: { url } }) => {
        return {
          ...state,
          utilityAuthURL: url,
          isLoading: false
        };
      }
    },

    [fetchUtilityMeters]: {
      next: (state, { payload: { data } }) => {
        return {
          ...state,
          ...data,
          isLoading: false
        };
      }
    },

    [getRateTypes]: {
      next: (state, { payload: { data: rateTypes } }) => {
        return {
          ...state,
          rateTypes,
          isLoading: false
        };
      }
    },

    [selectMetersForActivation]: {
      next: (state, { payload }) => {
        const meters = state.meters.map(meter => {
          return {
            ...meter,
            selected: payload.includes(meter.number)
          };
        });

        return {
          ...state,
          meters,
          isLoading: false
        };
      }
    },

    [validateTokenResponse]: {
      next: (state, { payload }, token) => {
        return {
          ...state,
          error: null,
          prospectId: payload.id,
          nextStep: payload.nextStepToComplete,
          isLoading: false,
          validationToken: payload.token,
          email: payload.email
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          error: payload,
          token: null,
          isLoading: false
        };
      }
    },

    [signupResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          error: null,
          // prospectId: payload.id,
          // nextStep: payload.nextStepToComplete,
          isLoading: false,
          validationToken: payload.token,
          email: payload.emailAddress,
          firstName: payload.firstName,
          lastName: payload.lastName,
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          error: payload,
          isLoading: false
        };
      }
    },

    [setPasswordRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isLoading: true
        };
      }
    },

    [getOffers]: {
      next: (state, action) => {
        return {
          ...state,
          offers: action.payload,
          error: null,
          isLoading: false
        };
      }
    },

    [selectOffer]: {
      next: (state, { payload }) => {
        return {
          ...state,
          offers: setOffer(state.offers, payload),
          error: null,
          isLoading: false,
          selectedOffer: payload
        };
      }
    },

    [getLegalTemplate]: {
      next: (state, { payload }) => {
        return {
          ...state,
          legal: payload,
          error: null,
          isLoading: false
        };
      }
    },

    [validateContractRequest]: {
      next: (state, { payload }) => {
        return {
          ...state,
          error: null,
          isLoading: true,
          contractReady: false,
          isCheckingContract: true
        };
      }
    },

    [validateContractResponse]: {
      next: (state, { payload }) => {
        let contractReady = payload.reponse && payload.response.status === 404 ? false : true;
        return {
          ...state,
          error: null,
          isLoading: true,
          contractReady: contractReady,
          isCheckingContract: contractReady ? false : true
        };
      }
    },

    [invalidateContractRequest]: {
      next: (state, { payload }) => {
        return {
          ...state,
          error: null,
          isLoading: false,
          contractReady: false,
          isCheckingContract: false
        };
      }
    },

    [fetchContractDetailsResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          error: null,
          isLoading: false,
          review: payload
        };
      }
    },

    [fetchContractDetailsWithProspectIdResponse]: {
      next: (state, { payload }) => {
        return {
          ...state,
          error: null,
          isLoading: false,
          review: payload
        };
      }
    },

    [sign]: {
      next: (state, { payload }) => {
        return {
          ...state,
          ...payload,
          error: null,
          isLoading: false
        };
      }
    },

    [setPasswordResponse]: {
      next: (state, { payload: { data } }) => {
        return {
          ...state,
          error: null,
          prospectId: data.id,
          nextStep: data.nextStepToComplete,
          isLoading: false
        };
      },
      throw: (state, { payload }) => {
        return {
          ...state,
          error: payload,
          isLoading: false
        };
      }
    },

    [creditCheckRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isSubmitting: true
        };
      }
    },

    [creditCheckResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          nextStep: action.payload.nextStepToComplete,
          isSubmitting: false
        };
      }
    },

    [fetchClaimTicketRequest]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          isSubmitting: true
        };
      }
    },

    [fetchClaimTicketResponse]: {
      next: (state, action) => {
        return {
          ...state,
          error: null,
          nextStep: action.payload.nextStepToComplete,
          claimTicket: action.payload.claimTicket,
          isSubmitting: false
        };
      }
    },

    [skipUtilityLookup]: {
      next: state => ({
        ...state,
        useUtilityLookup: false
      })
    },

    [useUtilityLookup]: {
      next: state => ({
        ...state,
        useUtilityLookup: true
      })
    },

    [fetchCreditCheckActionResponse]: {
      next: (state, action) => ({
        ...state,
        nextStep: action.payload.nextStepToComplete
      })
    },

    [fetchAllAccountsResponse]: {
      next: (state, action) => {
        if (action.payload.length && action.payload[0].role === 'prospect') {
          return {
            ...state,
            canUseUtilityApiLookup: action.payload[0].canUseUtilityApiLookup
          };
        }
        return state;
      }
    },

    [fetchProspectResponse]: {
      next: (state, action) => {
        return {
          ...state,
          promo: action.payload.promo
        };
      }
    }
  },

  defaultState
);
