import { createAction } from 'redux-actions';

const MODULE = 'app/users';

const CREATE_REQUEST = `${MODULE}/CREATE_REQUEST`;
const CREATE_RESPONSE = `${MODULE}/CREATE_RESPONSE`;
const DISABLE_REQUEST = `${MODULE}/DISABLE_REQUEST`;
const DISABLE_RESPONSE = `${MODULE}/DISABLE_RESPONSE`;
const EMAIL_UPDATE_REQUEST = `${MODULE}/EMAIL_UPDATE_REQUEST`;
const EMAIL_UPDATE_RESPONSE = `${MODULE}/EMAIL_UPDATE_RESPONSE`;
const FETCH_ADMINS_REQUEST = `${MODULE}/FETCH_ADMINS_REQUEST`;
const FETCH_ADMINS_RESPONSE = `${MODULE}/FETCH_ADMINS_RESPONSE`;
const FETCH_INVITED_REQUEST = `${MODULE}/FETCH_INVITED_REQUEST`;
const FETCH_INVITED_RESPONSE = `${MODULE}/FETCH_INVITED_RESPONSE`;
const INVITE_RESEND_REQUEST = `${MODULE}/INVITE_RESEND_REQUEST`;
const INVITE_RESEND_RESPONSE = `${MODULE}/INVITE_RESEND_RESPONSE`;
const ROLE_ASSIGN_REQUEST = `${MODULE}/ROLE_ASSIGN_REQUEST`;
const ROLE_ASSIGN_RESPONSE = `${MODULE}/ROLE_ASSIGN_RESPONSE`;
const FETCH_USER_ROLES_REQUEST = `${MODULE}/ROLES_REQUEST`;
const FETCH_USER_ROLES_RESPONSE = `${MODULE}/ROLES_RESPONSE`;
const UNLOCK_USER_REQUEST = `${MODULE}/UNLOCK_USER_REQUEST`;
const UNLOCK_USER_RESPONSE = `${MODULE}/UNLOCK_USER_RESPONSE`;
const UPDATE_REQUEST = `${MODULE}/UPDATE_REQUEST`;
const UPDATE_RESPONSE = `${MODULE}/UPDATE_RESPONSE`;
const USERS_FETCH_REQUEST = `${MODULE}/USERS_FETCH_REQUEST`;
const USERS_FETCH_RESPONSE = `${MODULE}/USERS_FETCH_RESPONSE`;
const USER_DISABLE_REQUEST = `${MODULE}/USER_DISABLE_REQUEST`;
const USER_DISABLE_RESPONSE = `${MODULE}/USER_DISABLE_RESPONSE`;
const USER_EMAIL_UPDATE_REQUEST = `${MODULE}/USER_EMAIL_UPDATE_REQUEST`;
const USER_EMAIL_UPDATE_RESPONSE = `${MODULE}/USER_EMAIL_UPDATE_RESPONSE`;
const USER_REGISTER_REQUEST = `${MODULE}/USER_REGISTER_REQUEST`;
const USER_REGISTER_RESPONSE = `${MODULE}/USER_REGISTER_RESPONSE`;
const USER_ROLE_ASSIGN_REQUEST = `${MODULE}/USER_ROLE_ASSIGN_REQUEST`;
const USER_ROLE_ASSIGN_RESPONSE = `${MODULE}/USER_ROLE_ASSIGN_RESPONSE`;
const USER_UPDATE_REQUEST = `${MODULE}/USER_UPDATE_REQUEST`;
const USER_UPDATE_RESPONSE = `${MODULE}/USER_UPDATE_RESPONSE`;
const UPDATE_DEFAULT_USER_REQUEST = `${MODULE}/UPDATE_DEFAULT_USER_REQUEST`;
const UPDATE_DEFAULT_USER_RESPONSE = `${MODULE}/UPDATE_DEFAULT_USER_RESPONSE`;

export const assignRoleRequest = createAction(ROLE_ASSIGN_REQUEST);
export const assignRoleResponse = createAction(ROLE_ASSIGN_RESPONSE);
export const assignUserRoleRequest = createAction(USER_ROLE_ASSIGN_REQUEST);
export const assignUserRoleResponse = createAction(USER_ROLE_ASSIGN_RESPONSE);
export const createRequest = createAction(CREATE_REQUEST);
export const createResponse = createAction(CREATE_RESPONSE);
export const disableRequest = createAction(DISABLE_REQUEST);
export const disableResponse = createAction(DISABLE_RESPONSE);
export const disableUserRequest = createAction(USER_DISABLE_REQUEST);
export const disableUserResponse = createAction(USER_DISABLE_RESPONSE);
export const fetchAdminsRequest = createAction(FETCH_ADMINS_REQUEST);
export const fetchAdminsResponse = createAction(FETCH_ADMINS_RESPONSE);
export const fetchInvitedRequest = createAction(FETCH_INVITED_REQUEST);
export const fetchInvitedResponse = createAction(FETCH_INVITED_RESPONSE);
export const fetchUsersRequest = createAction(USERS_FETCH_REQUEST);
export const fetchUsersResponse = createAction(USERS_FETCH_RESPONSE);
export const fetchUserRolesRequest = createAction(FETCH_USER_ROLES_REQUEST);
export const fetchUserRolesResponse = createAction(FETCH_USER_ROLES_RESPONSE);
export const registerUserRequest = createAction(USER_REGISTER_REQUEST);
export const registerUserResponse = createAction(USER_REGISTER_RESPONSE);
export const resendInviteRequest = createAction(INVITE_RESEND_REQUEST);
export const resendInviteResponse = createAction(INVITE_RESEND_RESPONSE);
export const unlockUserRequest = createAction(UNLOCK_USER_REQUEST);
export const unlockUserResponse = createAction(UNLOCK_USER_RESPONSE);
export const updateEmailRequest = createAction(EMAIL_UPDATE_REQUEST);
export const updateEmailResponse = createAction(EMAIL_UPDATE_RESPONSE);
export const updateRequest = createAction(UPDATE_REQUEST);
export const updateResponse = createAction(UPDATE_RESPONSE);
export const updateUserEmailRequest = createAction(USER_EMAIL_UPDATE_REQUEST);
export const updateUserEmailResponse = createAction(USER_EMAIL_UPDATE_RESPONSE);
export const updateUserRequest = createAction(USER_UPDATE_REQUEST);
export const updateUserResponse = createAction(USER_UPDATE_RESPONSE);
export const updateDefaultUserRequest = createAction(UPDATE_DEFAULT_USER_REQUEST);
export const updateDefaultUserResponse = createAction(UPDATE_DEFAULT_USER_RESPONSE);
