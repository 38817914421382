import { AccountPicker } from './AccountPicker';
import { ActivateAccount } from './ActivateAccount';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { Logout } from './Logout';
import { ResetPassword } from './ResetPassword';

const AuthRoutes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    component: Logout
  },
  {
    path: '/activate',
    component: ActivateAccount
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    component: ResetPassword
  },
  {
    path: '/accounts',
    component: AccountPicker
  }
];

export default AuthRoutes;
