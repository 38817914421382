import * as Yup from 'yup';

export default function generatePasswordSchema(rules) {
  let schema = Yup.string()
    .required('Password is required')
    .matches(/^(?=.*[a-z]).*$/, 'Passwords must have at least one lowercase letter.')
    .matches(/^(?=.*[0-9]).*$/, 'Passwords must have at least one number.')
    .matches(/^(?=.*[^a-zA-Z0-9]).*$/, 'Passwords must have at least one non letter or digit character.')
    .matches(/^(?=.*[A-Z]).*$/, 'Passwords must have at least one capital letter.');

  if (rules.length) {
    schema = schema.min(rules.length, `Password must be at least ${rules.length} characters`);
  }

  return schema;
}
