import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AuthRoutes from 'app/Auth/Routes';
import PrivacyPolicy from 'app/PrivacyPolicy';
import Terms from 'app/Terms';
import asyncComponent from 'shared/utilities/asyncComponent';
import { Head } from 'shared/components/Head';
import { EnvironmentHeader } from 'shared/components/EnvironmentHeader';
import { Session } from 'shared/components/Session';
import { ShardProvider } from 'shared/components/ShardProvider';
import { history } from 'shared/store/reducers';

const ResidentialRoutes = asyncComponent(() => import('app/Residential').then(module => module.default));
const SignupRoutes = asyncComponent(() => import('app/Signup').then(module => module.default));

export function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <ShardProvider>
        <Head />
        <EnvironmentHeader />

        <ConnectedRouter history={history}>
          <Switch>
            {AuthRoutes.map((route, index) => (
              <Route component={route.component} key={index} path={route.path} />
            ))}
            <Route component={ResidentialRoutes} path="/residential" />
            <Route component={SignupRoutes} path="/signup" />
            <Route component={PrivacyPolicy} path="/privacy" />
            <Route component={Terms} path="/terms" />
            <Route path="/" render={() => <Session />} />
          </Switch>
        </ConnectedRouter>
      </ShardProvider>
    </GoogleOAuthProvider>
  );
}
