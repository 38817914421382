import { parse } from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link, Redirect } from 'react-router-dom';

import { AuthLayout } from 'shared/components/AuthLayout';
import { ProspectTypeStrings } from 'shared/models/PropsectType';
import { resetPassword } from 'shared/store/modules/account/effects';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import { fetchAccounts } from 'shared/store/modules/accounts/effects';
import { login } from 'shared/store/modules/auth/effects';
import { getPasswordRules } from 'shared/store/modules/provider/selectors';

import ActivateAccountForm from './forms/ActivateAccountForm';

export function ActivateAccount() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const rules = useSelector(state => getPasswordRules(state));

  const { token, prospectType } = parse(location.search);

  function handleSubmit(username: string, newPassword: string, token: string) {
    return dispatch(resetPassword(username, newPassword, token));
  }

  async function handleSuccess(values: any) {
    try {
      const { username, password } = values;

      const response = (await dispatch(login(username, password))) as any;
      let redirectTo = '/';

      if (response.role !== 'admin') {
        const accounts = (await dispatch(fetchAccounts())) as any;

        if (accounts.length === 1) {
          dispatch(setActiveAccountId(accounts[0].id));
          if (prospectType && prospectType !== ProspectTypeStrings.Residential) {
            redirectTo = '/signup/next'
          }
        } else {
          redirectTo = '/accounts';
        }
      }

      history.push(redirectTo);
    } catch (error) {
      return error;
    }
  }

  if (!token) {
    return <Redirect to="/" />;
  }

  return (
    <AuthLayout title="Activate Account">
      <ActivateAccountForm onSubmit={handleSubmit} onSuccess={handleSuccess} rules={rules} token={token} />

      <p className="text-center text-muted mb-0 px-5">
        <small>
          By clicking the Activate Account button, you agree to our{' '}
          <Link target="_blank" to="/terms">
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link target="_blank" to="/privacy">
            Privacy Policy
          </Link>
        </small>
      </p>
    </AuthLayout>
  );
}
