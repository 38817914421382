import { AxiosResponse, AxiosError } from 'axios';

import { AppThunk } from 'shared/store/types';

import { loginRequest, loginResponse, renewSessionRequest, renewSessionResponse } from './actions';
import { selectRefreshToken } from './selectors';
import { Account, LoginResponse, FetchAccountsResponse } from './types';

export function login(username: string, password: string): AppThunk<Promise<LoginResponse>> {
  return function loginThunk(dispatch, _getState, { api }) {
    return new Promise<LoginResponse>((resolve, reject) => {
      dispatch(loginRequest());

      return api.auth
        .login(username, password)
        .then(({ data }: AxiosResponse<LoginResponse>) => {
          dispatch(loginResponse(data));
          return resolve(data);
        })
        .catch((error: AxiosError) => {
          dispatch(loginResponse(error));
          return reject({ error: 'Incorrect email address or password' });
        });
    });
  };
}

export function fetchAccounts(): AppThunk<Promise<Account[]>> {
  return function fetchAccountsThunk(_dispatch, _getState, { api }) {
    return new Promise<Account[]>((resolve, reject) => {
      return api.user.fetchAccounts().then(
        ({ data }: AxiosResponse<FetchAccountsResponse>) => resolve(data.data),
        () => reject({ error: 'Error fetching accounts' })
      );
    });
  };
}

export function renewSession(): AppThunk<Promise<LoginResponse>> {
  return function renewSessionThunk(dispatch, getState, { api }) {
    return new Promise<LoginResponse>((resolve, reject) => {
      dispatch(renewSessionRequest());

      const refreshToken = selectRefreshToken(getState());

      return api.auth
        .renewSession(refreshToken)
        .then(({ data }: AxiosResponse<LoginResponse>) => {
          dispatch(renewSessionResponse(data));
          return resolve(data);
        })
        .catch((error: AxiosError) => {
          dispatch(renewSessionResponse(error));
          return reject({ error: 'Session has expired' });
        });
    });
  };
}
