import qs from 'qs';

export class AuthService {
  authHttp;

  constructor(api) {
    this.authHttp = api.authHttp;
  }

  async login(username, password) {
    const data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: 'password',
      username,
      password
    };

    try {
      const response = await this.authHttp.post('/token', qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async loginWithGoogle(access_token, refresh_token) {
    const data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: 'google_token',
      google_access_token: access_token,
      google_refresh_token: refresh_token
    };

    try {
      const response = await this.authHttp.post('/token', qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response === undefined) return Promise.reject({error: 'Response is undefined'});

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async renewSession(token) {
    const data = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: 'refresh_token',
      refresh_token: token
    };

    try {
      const response = await this.authHttp.post('/token', qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
