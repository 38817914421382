import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Alert, Button, Form, FormFeedback, FormGroup, Input, UncontrolledPopover, PopoverBody } from 'reactstrap';
import PasswordRequirements from 'shared/components/PasswordRequirements';
import * as Yup from 'yup';

import Icon from 'shared/components/Icon';
import generatePasswordSchema from 'shared/utilities/generatePasswordSchema';
import styles from './styles';

class ActivateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        username: '',
        password: '',
        passwordConfirmation: ''
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validationSchema = this.validationSchema.bind(this);
  }

  componentDidMount() {
    this.textInput.focus();
  }

  async handleSubmit({ username, password }, { setSubmitting, setErrors, resetForm }) {
    const { error, payload } = await this.props.onSubmit(username, password, this.props.token);

    if (error) {
      const msg = payload.response.data.error_description || payload.message;
      setSubmitting(false);
      resetForm();
      setErrors({ error: msg });
      this.textInput.focus();
    } else {
      typeof this.props.onSuccess === 'function' &&
        this.props.onSuccess({
          username,
          password
        });
    }
  }

  validationSchema() {
    const { rules } = this.props;

    return Yup.object().shape({
      username: Yup.string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
      password: generatePasswordSchema(rules),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
        .required('Password confirmation is required')
    });
  }

  render() {
    const { values } = this.state;
    const { rules } = this.props;

    return (
      <div css={styles}>
        <Formik
          initialValues={values}
          onSubmit={this.handleSubmit}
          render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
            <Form autoComplete="nope" className="form-auth form-activate" onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  autoFocus
                  disabled={isSubmitting}
                  id="username"
                  innerRef={input => (this.textInput = input)}
                  invalid={!!touched.username && !!errors.username}
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Email Address"
                  type="email"
                  value={values.username}
                />

                <FormFeedback>{errors.username}</FormFeedback>
              </FormGroup>

              <FormGroup className="position-relative">
                <Input
                  className="pr-5"
                  disabled={isSubmitting}
                  id="password"
                  invalid={!!touched.password && !!errors.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Password"
                  type="password"
                  value={values.password}
                />

                <div className="password-requirements text-info" id="popover">
                  <Icon icon="info" />
                </div>

                <UncontrolledPopover placement="right" target="popover" trigger="hover">
                  <PopoverBody>
                    <PasswordRequirements rules={rules} />
                  </PopoverBody>
                </UncontrolledPopover>

                <FormFeedback>{errors.password}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Input
                  disabled={isSubmitting}
                  id="passwordConfirmation"
                  invalid={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
                  name="passwordConfirmation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  type="password"
                  value={values.passwordConfirmation}
                />

                <FormFeedback>{errors.passwordConfirmation}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Button block color="primary" disabled={isSubmitting || !isValid} size="lg" type="submit">
                  {isSubmitting ? 'Activating ...' : 'Activate Account'}
                </Button>
              </FormGroup>

              {errors.error && (
                <Alert className="text-center mt-3 mb-0" color="danger">
                  {errors.error}
                </Alert>
              )}
            </Form>
          )}
          validationSchema={this.validationSchema}
        />
      </div>
    );
  }
}

export default withRouter(ActivateForm);
