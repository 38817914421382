import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLogout, useSession } from 'shared/store/modules/auth/hooks';
import { useIdleTimer } from 'react-idle-timer';

const IDLE_TIMEOUT = moment.duration(25, 'minutes').asMilliseconds();
const LOGOUT_TIMEOUT = moment.duration(30, 'minutes').asMilliseconds();
const TIMEOUT = LOGOUT_TIMEOUT - IDLE_TIMEOUT;

export function SessionTimer() {
  const logout = useLogout();
  const { accessTokenExpirationDate, isRenewing, renewSession } = useSession();

  const expirationDate = useMemo(() => {
    return moment(accessTokenExpirationDate).startOf('second');
  }, [accessTokenExpirationDate]);

  const calculateSessionTimeRemaining = useCallback(() => {
    return moment.duration(expirationDate.diff(moment(new Date()).startOf('second'))).asMilliseconds();
  }, [expirationDate]);

  const [sessionTimeRemaining, setSessionTimeRemaining] = useState(calculateSessionTimeRemaining);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSessionTimeRemaining(calculateSessionTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateSessionTimeRemaining]);

  useEffect(() => {
    setShowModal(sessionTimeRemaining <= moment.duration(5, 'minutes').asMilliseconds());
  }, [sessionTimeRemaining]);

  const [isIdle, setIsIdle] = useState(false);
  const [idleTimeRemaining, setIdleTimeRemaining] = useState(TIMEOUT);

  const { pause, reset } = useIdleTimer({
    timeout: IDLE_TIMEOUT,
    debounce: 250,
    crossTab: {
      emitOnAllTabs: true
    },

    onIdle: () => {
      pause();
      setIsIdle(true);
    },

    onActive: () => {
      setIsIdle(false);
      setTimeout(() => {
        setIdleTimeRemaining(TIMEOUT);
      }, 100);
    }
  });

  useEffect(() => {
    let interval: number;

    if (isIdle) {
      interval = setInterval(() => {
        setIdleTimeRemaining(idleTimeRemaining => {
          if (idleTimeRemaining >= 1000) {
            return idleTimeRemaining - 1000;
          }

          return idleTimeRemaining;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [idleTimeRemaining, isIdle]);

  useEffect(() => {
    if (idleTimeRemaining < 1000) {
      logout();
    }
  }, [logout, idleTimeRemaining]);

  return (
    <Modal isOpen={isIdle || showModal}>
      <ModalHeader>Your session is about to expire</ModalHeader>

      <ModalBody>
        Your session will expire soon and you will be automatically signed out. Would you like to stay signed in?
      </ModalBody>

      <ModalFooter>
        <Button color="link" disabled={isRenewing} onClick={logout}>
          Sign out
        </Button>

        <Button
          color="primary"
          disabled={isRenewing}
          onClick={() => {
            reset();
            setShowModal(false);
            renewSession();
          }}
        >
          {isRenewing ? 'Loading ...' : 'Stay signed in'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
