import { createSelector } from 'reselect';

export function selectFeatures(state) {
  return state.features;
}

export const selectIsWelcomeMessageEnabled = createSelector([selectFeatures], features => {
  return features.enableWelcomeMessage;
});

export const selectIsPaymentMessageEnabled = createSelector([selectFeatures], features => {
  return features.enablePaymentMessage;
});

export const selectIsStreamlinedSignUpFlowEnabled = createSelector([selectFeatures], features => {
  return features.enableStreamlinedSignUpFlows;
});

export const selectIsResidentialRegistrationEnabled = createSelector([selectFeatures], features => {
  return features.isResidentialRegistrationEnabled;
});

export const selectIsResidentialExpectedSavingsDisabled = createSelector([selectFeatures], features => {
  return features.isResidentialExpectedSavingsDisabled;
});

export const selectHubspotChatUrl = createSelector([selectFeatures], features => {
  return features.hubspotChatUrl;
});
