import React from 'react';

const PasswordRequirements = ({ rules }) => (
  <div>
    <p>Password must be at least {rules?.length ? rules?.length : '6'} characters.</p>
    <p>Passwords must have at least one lowercase letter (a-z).</p>
    <p>Passwords must have at least one capital letter (A-Z).</p>
    <p>Passwords must have at least one number (0-9).</p>
    <p>Passwords must have at least one non letter or digit character.</p>
  </div>
);

export default PasswordRequirements;
