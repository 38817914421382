import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getProviderName } from 'shared/store/modules/provider/selectors';
import { selectThemeImage, selectThemeLogo } from 'shared/store/modules/theme/selectors';

import { Layout, AuthContainer } from './AuthLayout.styles';

type AuthLayoutProps = PropsWithChildren<{
  title: string;
}>;

export function AuthLayout(props: AuthLayoutProps) {
  const { children, title } = props;

  const image = useSelector(state => selectThemeImage(state));
  const logo = useSelector(state => selectThemeLogo(state));
  const name = useSelector(state => getProviderName(state));

  return (
    <Layout image={image}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ToastContainer />

      <AuthContainer>
        <Link className="d-block" to="/">
          <img alt={`${name} Logo`} className="d-block ml-auto mr-auto mb-4" src={logo} title={name} width="50%" />
        </Link>

        {children}
      </AuthContainer>
    </Layout>
  );
}
