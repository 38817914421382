import { AxiosResponse } from 'axios';
import { normalize } from 'normalizr';

import { addEntities } from 'shared/store/modules/entities';
import { AppThunk } from 'shared/store/types';

import { fetchAllAccountsRequest, fetchAllAccountsResponse } from './actions';
import { Account, FetchAccountsResponse } from './types';

export function fetchAccounts(): AppThunk<Promise<Account[]>> {
  return function fetchAccountsThunk(dispatch, _getState, { api, schemas }) {
    return new Promise<Account[]>((resolve, reject) => {
      dispatch(fetchAllAccountsRequest());

      return api.user.fetchAccounts().then(
        ({ data }: AxiosResponse<FetchAccountsResponse>) => {
          const accounts = data.data;
          dispatch(fetchAllAccountsResponse(accounts));

          const entities = normalize(accounts, schemas.accounts);
          dispatch(addEntities(entities));

          return resolve(accounts);
        },
        () => reject({ error: 'Error fetching accounts' })
      );
    });
  };
}
