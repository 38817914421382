export enum ProspectType {
  Residential = 0,
  GoogleSignUp = 1,
  SunCentralSignUp = 2,
  CondensedFlowWaitlist = 3
};

export const ProspectTypeStrings: any = {
  Residential: '0',
  GoogleSignUp: '1',
  SunCentralSignUp: '2',
  CondensedFlowWaitlist: '3'
}
