import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { RouteChildrenProps, useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { AuthLayout } from 'shared/components/AuthLayout';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import { fetchAccounts } from 'shared/store/modules/accounts/effects';
import { selectIsStreamlinedSignUpFlowEnabled } from 'shared/store/modules/features/selectors';
import { checkInquiryCapacity } from 'shared/store/modules/prospects/effects';
import { login } from 'shared/store/modules/auth/effects';
import { AppDispatch } from 'shared/store/types';
import Icon from 'shared/components/Icon';

import { LoginForm } from './forms/LoginForm';
import { GoogleSigninButton } from './GoogleSigninButton';
import { fetchProspect } from 'shared/store/modules/prospects/effects';

type LoginProps = RouteChildrenProps<{}, { from: string }>;

export function Login(props: LoginProps) {
  const { search } = useLocation();
  const { alreadyHasAccount } = parse(search, { parseBooleans: true });
  const { location } = props;
  const { state } = location;
  const { from } = state || { from: '/' };
  const [error, setError] = useState<string | undefined>(undefined);
  const [hasGoogleSignInError, setHasGoogleSignInError] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const googleLoginEnabled = useSelector(selectIsStreamlinedSignUpFlowEnabled);
  const history = useHistory();

  async function handleSubmit(username: string, password: string) {
    try {
      const response = await dispatch(login(username, password));

      let redirectTo = '/';

      if (response.role !== 'admin') {
        const accounts = await dispatch(fetchAccounts());

        if (accounts.length === 1) {
          dispatch(setActiveAccountId(accounts[0].id));
          if (
            response.isStreamlinedProspect &&
            accounts[0].nextStepToComplete &&
            accounts[0].nextStepToComplete === 600
          ) {
            const prospect = await dispatch(fetchProspect(accounts[0].id));
            if (prospect) {
              const { associatedUtilityId, stateId } = prospect.payload;
              await dispatch(checkInquiryCapacity(null, associatedUtilityId, stateId))
                .then((data: any) => {
                  if (data?.payload?.data) {
                    const { programsAndCapacityAvailable } = data.payload.data;
                    if (!programsAndCapacityAvailable) {
                      redirectTo = '/signup/review?addToWaitlist=true';
                    }
                  }
                })
                .catch(error => {
                  console.error('error ==>', error);
                });
            }
          }
        } else {
          redirectTo = '/accounts';
        }
      }

      history.push(redirectTo);
    } catch (error) {
      return error;
    }
  }

  return (
    <AuthLayout title="Login">
      {!!error && (
        <Alert className="text-center" color="danger">
          {error}
        </Alert>
      )}
      {googleLoginEnabled && hasGoogleSignInError && (
        <Alert color="info" style={{ display: 'flex', padding: '1rem 0.75rem' }}>
          <div>
            <Icon icon="info" className="mt-1 mr-1" style={{ fontSize: '18px' }} />
          </div>
          <div>
            Sorry, we don’t have an account associated with that email.{' '}
            <Link style={{ textDecoration: 'underline' }} to="/signup">
              Create an account
            </Link>{' '}
            to get started.
          </div>
        </Alert>
      )}
      <LoginForm onError={setError} onSubmit={handleSubmit} redirectTo={from} alreadyHasAccount={alreadyHasAccount} />
      {googleLoginEnabled && <GoogleSigninButton setInvalidUserResponse={setHasGoogleSignInError} />}
      <small className="d-block text-center mt-3">
        <Link to="/forgot-password">Forgot Password?</Link>
      </small>
    </AuthLayout>
  );
}
