import { withFormik, FormikProps } from 'formik';
import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, PopoverBody, UncontrolledPopover } from 'reactstrap';
import PasswordRequirements from 'shared/components/PasswordRequirements';
import * as Yup from 'yup';
import styles from './styles';

import Icon from 'shared/components/Icon';
import generatePasswordSchema from 'shared/utilities/generatePasswordSchema';

interface ResetPasswordFormValues {
  username: string;
  password: string;
  passwordConfirmation: string;
  token: string;
}

type ResetPasswordFormWrapperProps = {
  onError: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSubmit: (username: string, password: string, token: string) => any;
  onSuccess: (username: string, password: string) => any;
  rules: any;
  token: string;
};

function ResetPasswordForm(props: FormikProps<ResetPasswordFormValues> & ResetPasswordFormWrapperProps) {
  const { rules, ...formikProps } = props;
  const { touched, values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, isValid } = formikProps;

  return (
    <div css={styles}>
      <Form autoComplete="nope" className="form-auth form-reset-password" onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            autoFocus
            disabled={isSubmitting}
            id="username"
            invalid={!!touched.username && !!errors.username}
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email Address"
            type="email"
            value={values.username}
          />

          <FormFeedback>{errors.username}</FormFeedback>
        </FormGroup>

        <FormGroup className="position-relative">
          <Input
            className="pr-5"
            disabled={isSubmitting}
            id="password"
            invalid={!!touched.password && !!errors.password}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Password"
            type="password"
            value={values.password}
          />

          <div className="password-requirements text-info" id="popover">
            <Icon icon="info" />
          </div>

          <UncontrolledPopover placement="right" target="popover" trigger="hover">
            <PopoverBody>
              <PasswordRequirements rules={rules} />
            </PopoverBody>
          </UncontrolledPopover>

          <FormFeedback>{errors.password}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Input
            disabled={isSubmitting}
            id="passwordConfirmation"
            invalid={!!touched.passwordConfirmation && !!errors.passwordConfirmation}
            name="passwordConfirmation"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Confirm Password"
            type="password"
            value={values.passwordConfirmation}
          />

          <FormFeedback>{errors.passwordConfirmation}</FormFeedback>
        </FormGroup>

        <Button block color="primary" disabled={isSubmitting || !isValid} size="lg" type="submit">
          {isSubmitting ? 'Resetting ...' : 'Reset Password'}
        </Button>
      </Form>
    </div>
  );
}

const ResetPasswordFormWrapper = withFormik<ResetPasswordFormWrapperProps, ResetPasswordFormValues>({
  enableReinitialize: true,

  mapPropsToValues: props => ({
    username: '',
    password: '',
    passwordConfirmation: '',
    token: props.token
  }),

  validationSchema: (props: ResetPasswordFormWrapperProps) => {
    const { rules } = props;

    return Yup.object().shape({
      username: Yup.string()
        .email('Please enter a valid email address')
        .required('Email address is required'),

      password: generatePasswordSchema(rules),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords do not match.')
        .required('Password confirmation is required')
    });
  },

  handleSubmit: async (values, bag) => {
    const { props, setSubmitting, resetForm } = bag;
    const { username, password, token } = values;

    const response = await props.onSubmit(username, password, token);

    if (response.error) {
      resetForm({
        username,
        password: '',
        passwordConfirmation: '',
        token
      });

      setSubmitting(false);
      props.onError('There was an error resetting your password.');
    } else {
      props.onSuccess(username, password);
    }
  }
})(ResetPasswordForm);

export { ResetPasswordFormWrapper as ResetPasswordForm };
