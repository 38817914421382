import { parse } from 'query-string';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { AuthLayout } from 'shared/components/AuthLayout';
import { fetchAccounts } from 'shared/store/modules/accounts/effects';
import { getPasswordRules } from 'shared/store/modules/provider/selectors';
import { login } from 'shared/store/modules/auth/effects';
import { resetPassword } from 'shared/store/modules/account/effects';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useLogout } from 'shared/store/modules/auth/hooks';

import { ResetPasswordForm } from './forms/ResetPasswordForm';

export function ResetPassword() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const logout = useLogout();
  const rules = useSelector(state => getPasswordRules(state));
  const [error, setError] = useState<string | undefined>(undefined);

  const { token } = parse(location.search);

  if (!token) {
    return <Redirect to="/" />;
  }

  function handleSubmit(username: string, newPassword: string, token: string) {
    return dispatch(resetPassword(username, newPassword, token));
  }

  async function handleSuccess(username: string, password: string) {
    logout();

    try {
      const response = await dispatch(login(username, password));
      let redirectTo = '/';

      if (response.role !== 'admin') {
        const accounts = await dispatch(fetchAccounts());

        if (accounts.length === 1) {
          dispatch(setActiveAccountId(accounts[0].id));
        } else {
          redirectTo = '/accounts';
        }
      }

      history.push(redirectTo);
    } catch (error) {
      return error;
    }
  }

  return (
    <AuthLayout title="Reset Password">
      {!!error && (
        <Alert className="text-center" color="danger">
          {error}
        </Alert>
      )}
      <ResetPasswordForm
        onError={setError}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        rules={rules}
        token={`${token}`}
      />

      <small className="d-block text-center mt-3">
        <Link to="/login">Back to Login</Link>
      </small>
    </AuthLayout>
  );
}
