export default Object.freeze({
  admin: 'Admin',
  accountManager: 'Account Manager',
  accountReadOnly: 'Read Only',
  portfolioManager: 'Portfolio Manager',
  prospect: 'Prospect',
  Admin: 'admin',
  'Account Manager': 'accountManager',
  'Read Only': 'accountReadOnly',
  'Portfolio Manager': 'portfolioManager',
  Prospect: 'prospect'
});
