import { Redirect } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import { ApplicationLayout } from 'shared/components/ApplicationLayout';
import { UserRole } from 'shared/models';
import { useAccounts } from 'shared/store/modules/accounts/hooks';
import { useSession, useLogout } from 'shared/store/modules/auth/hooks';

export function Session() {
  const logout = useLogout();
  const { activeAccountId } = useAccounts();
  const { hasValidAccessToken, hasValidRefreshToken, userRole, renewSession, isStreamlinedProspect } = useSession();

  useEffect(() => {
    if (!hasValidRefreshToken) {
      logout();
    }
  }, [hasValidRefreshToken, logout]);

  const shouldRenew = useMemo(() => {
    return hasValidRefreshToken && !hasValidAccessToken;
  }, [hasValidAccessToken, hasValidRefreshToken]);

  useEffect(() => {
    if (shouldRenew) {
      renewSession().catch(logout);
    }
  }, [logout, renewSession, shouldRenew]);

  if (userRole === UserRole.Prospect) {
    if (isStreamlinedProspect === true) return <Redirect to="/signup/next" />;
    return <Redirect to="/residential/next" />;
  }

  if (!activeAccountId && userRole !== UserRole.Admin) {
    return <Redirect to="/accounts" />;
  }

  return <ApplicationLayout />;
}
