import { withFormik, FormikProps } from 'formik';
import { useEffect, useRef } from 'react';
import { Button, Form, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import * as Yup from 'yup';
import styles from './styles';

interface LoginFormValues {
  username: string;
  password: string;
}

type LoginFormWrapperProps = {
  onError: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSubmit: (username: string, password: string) => any;
  onSuccess?: () => void;
  redirectTo: string;
  alreadyHasAccount: any;
};

function LoginForm(props: FormikProps<LoginFormValues> & LoginFormWrapperProps) {
  const { onError, alreadyHasAccount = false, ...formikProps } = props;
  const { values, handleChange, handleSubmit, isSubmitting, isValid, submitCount } = formikProps;

  const usernameInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (usernameInput && usernameInput.current) {
      usernameInput.current.focus();
    }
  }, [submitCount]);

  function handleBlur(e: React.FocusEvent<any>) {
    onError(undefined);
    formikProps.handleBlur(e);
  }

  return (
    <div css={styles}>
      {alreadyHasAccount && (
        <div className="system-message">
          <FontAwesomeIcon
            fixedWidth
            icon={faInfoCircle}
            style={{ color: 'rgb(1,72,130)', float: 'left', transform: 'translateY(25%)' }}
            className="mr-2"
          />
          <p>It looks like you already have an account. Please log in to continue.</p>
        </div>
      )}
      {/* TODO: Comment in/out when displaying "under construction message" during large releases   */}
      {/* <div className="system-message">
        <FontAwesomeIcon
          fixedWidth
          icon={faInfoCircle}
          style={{ color: 'rgb(1,72,130)', float: 'left', transform: 'translateY(25%)' }}
          className="mr-2"
        />
        <p>
          We are currently down for maintenance, and expect to be back in a couple hours. Thank you for your patience.
        </p>
      </div> */}
      <Form className="form-auth form-login" onSubmit={handleSubmit}>
        <div>
          <Label className="sr-only" for="username">
            Email Address
          </Label>

          <Input
            autoFocus
            disabled={isSubmitting}
            id="username"
            innerRef={usernameInput}
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email Address"
            type="email"
            value={values.username}
          />
        </div>

        <div className="mb-3">
          <Label className="sr-only" for="password">
            Password
          </Label>

          <Input
            disabled={isSubmitting}
            id="password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Password"
            type="password"
            value={values.password}
          />
        </div>

        <Button block color="primary" disabled={isSubmitting || !isValid} size="lg" type="submit">
          {isSubmitting ? 'Signing In ...' : 'Sign in'}
        </Button>
      </Form>
    </div>
  );
}

const LoginFormWrapper = withFormik<LoginFormWrapperProps, LoginFormValues>({
  enableReinitialize: true,

  mapPropsToValues: () => ({
    username: '',
    password: ''
  }),

  validationSchema: Yup.object().shape({
    username: Yup.string()
      .label('Email Address')
      .required(),

    password: Yup.string()
      .label('Password')
      .required()
  }),

  handleSubmit: async (values, bag) => {
    const { props, setSubmitting, resetForm } = bag;
    const { username, password } = values;

    const response = await props.onSubmit(username, password);

    setSubmitting(false);

    if (response && response.error) {
      resetForm({
        username,
        password: ''
      });

      props.onError(response.error);
    } else {
      // props.history.push(props.redirectTo);
    }
  }
})(LoginForm);

export { LoginFormWrapper as LoginForm };
