import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { css } from '@emotion/react';
import { useHistory } from 'react-router';

import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { onError } from 'shared/hooks/onError';
import { signup } from 'shared/store/modules/prospects/effects';
import { loginWithGoogle } from 'shared/store/modules/prospects/effects';
import { fetchAccounts } from 'shared/store/modules/accounts/effects';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import sctheme from 'app/styles/sctheme';

const styles = () => css`
  .btn-google {
    color: ${sctheme.grays.$900};
    background: ${sctheme.white};
    border: 1px solid ${sctheme.grays.$300};
    height: 48px;
    width: 100%;
    font-weight: 600;

    img {
      margin-right: 8px;
    }
  }
`;

export function GoogleSigninButton({ setInvalidUserResponse }: { setInvalidUserResponse?: Function }) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: response => {
      setLoading(true);
      dispatch(signup(response.code)).then(async ({ payload }) => {
        const { payload: res } = await dispatch(loginWithGoogle(payload.accessToken, payload.refreshToken));
        const { error, role } = res;

        if (error) {
          setLoading(false);
          if (setInvalidUserResponse) setInvalidUserResponse(true);
          return;
        }

        let redirectTo = '/';

        if (role !== 'admin') {
          const accounts = await dispatch(fetchAccounts());

          if (accounts.length === 1) {
            dispatch(setActiveAccountId(accounts[0].id));
          } else {
            redirectTo = '/accounts';
          }
        }

        setLoading(false);
        history.push(redirectTo);
      });
    },
    onError: onError
  });

  return (
    <div css={styles} className="mt-3 btn-google-wrapper">
      <button className={`btn btn-google mb-3 ${loading ? 'disabled' : ''}`} onClick={() => login()} disabled={loading}>
        <img alt="Google" src="/images/icons/Google.svg" width="28" height="28" />
        Continue With Google
      </button>
    </div>
  );
}
