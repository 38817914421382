import React from 'react';
import { useHistory } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';

import { AuthLayout } from 'shared/components/AuthLayout';
import { useAccounts } from 'shared/store/modules/accounts/hooks';
import { useSession } from 'shared/store/modules/auth/hooks';

export function AccountPicker() {
  const history = useHistory();
  const { accounts, setActiveAccountId } = useAccounts();
  const { hasValidSession } = useSession();

  function handleClick(accountId: string) {
    setActiveAccountId(accountId);
    history.push('/');
  }

  if (!hasValidSession) {
    return <Redirect to="/login" />;
  }

  return (
    <AuthLayout title="Login">
      <p className="text-center font-weigh-bold">Select an Account</p>

      <ListGroup>
        {accounts.map(account => (
          <ListGroupItem action key={account.id} onClick={() => handleClick(account.id)} tag="button">
            {account.name}
          </ListGroupItem>
        ))}
      </ListGroup>

      <small className="d-block text-center mt-3">
        <Link to="/logout">Sign out</Link>
      </small>
    </AuthLayout>
  );
}
