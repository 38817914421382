import { createSelector } from 'reselect';
import { RootState } from 'shared/store/types';

function selectAuth(state: RootState) {
  return state.auth;
}

export const selectIsRenewing = createSelector([selectAuth], auth => auth.isRefreshing);
export const selectRole = createSelector([selectAuth], auth => auth.role);
export const selectIsStreamlinedProspect = createSelector([selectAuth], auth => auth.isStreamlinedProspect);
export const selectUserId = createSelector([selectAuth], auth => auth.userId);
export const selectUserName = createSelector([selectAuth], auth => auth.userName);

export const selectAccessToken = createSelector([selectAuth], auth => auth.refresh_token);
export const selectAccessTokenExpirationDate = createSelector([selectAuth], auth => auth.expires);

export const selectRefreshToken = createSelector([selectAuth], auth => auth.refresh_token);
export const selectRefreshTokenExpirationDate = createSelector([selectAuth], auth => auth.refresh_token_expires);
