import styled from 'styled-components';
import sctheme from 'app/styles/sctheme';

type LayoutProps = {
  image: string;
};

export const Layout = styled.div<LayoutProps>`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: ${sctheme.grays.$900};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
`;

export const AuthContainer = styled.div`
  margin: 75px auto 0;
  max-width: 500px;
  background-color: ${sctheme.white};
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 25px 35px 35px;
`;
